import { Injectable, inject, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment/environment.dev';
import { Landing } from '@interfaces/landing.interface';
import { Observable, catchError, of, retry } from 'rxjs';
import { response } from 'express';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  
  private readonly _http = inject( HttpClient );
  private readonly _endpoint = environment.apiURL;

  public lastUpdate = signal<string>('');
  public origenPage = signal<string>('');

  constructor() {
    this.getLastUpdate();
  }
  
  public getLandingData() { 
    return this._http.get<Landing>(`${this._endpoint}GetLanding`);
  }

  public getLastUpdate(): Observable<string> {
    const lastDate = new Date().getTime(); 
    return this._http.get(`${this._endpoint}GetLastUpdate?timestamp=${lastDate}`, { responseType: 'text' });
  }
  

  public updateOrigenPage(page: string, id?: string) {
    const updatedPage = id ? `${page}${encodeURIComponent(id)}` : page;
    this.origenPage.update(() => {
      return updatedPage;
    });
  }
}
